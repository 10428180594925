<script lang="ts" setup>
import type { Entry, EntryCollection, EntrySys } from "contentful";
import type { TypeButton } from "~/types/TypeButton";
import type { TypeTag } from "~/types/TypeTag";
import {
  isTypeArticleType,
  isTypeProject,
  isTypeStaff,
  isTypeTheme,
  isTypeTopic,
} from "~/types/contentful";
import type {
  TypeThemeSkeleton,
  TypeArticleSkeleton,
  TypeCardBlockArticle,
  TypeReportSkeleton,
  TypeTopicSkeleton,
  TypeProjectSkeleton,
  TypeDownloadSkeleton,
} from "~/types/contentful";

// Define props for the component
const props = defineProps<{
  fields: TypeCardBlockArticle<
    "WITHOUT_UNRESOLVABLE_LINKS",
    "en-GB"
  >["fields"] & { button?: TypeButton };
  sys: EntrySys;
}>();

const route = useRoute();

// Define reactive variables for filtering and searching
const input = ref("");
const currentPage = ref(Number(route.query[`page_${props.sys.id}`]) || 1);
const perPage = 18;
const container = ref<ComponentPublicInstance>();

// These are titles of filter blocks/groups, also used for prefixes for matching tags
const filterTitles = computed(() => props.fields.contentType === "Video" ? ["Year", "Video", "Theme", "Project"] : ["Year", "Theme", "Project"]);

const selectedFilters = reactive<Record<number, string[]>>({});

const compiledTags = computed(() => filterTitles.value.flatMap((filterPrefix, index) => selectedFilters[index]?.flatMap((selectedFilter) => allTags.value?.find((tag) => tag.name === `${filterPrefix}: ${selectedFilter}`)?.sys.id)).filter(Boolean).join(","));

// Fetch data from APIs
const { data: allThemes }
  = await useLazyFetch<
    Entry<
      TypeThemeSkeleton | TypeTopicSkeleton,
      "WITHOUT_UNRESOLVABLE_LINKS",
      "en-GB"
    >[]
  >("/api/themes");

const { data: allTags } = await useLazyFetch<TypeTag[]>("/api/tags", {
  // We're only interested in tags that start with one of our filter block titles
  transform: (tags) => tags.filter((tag) => filterTitles.value.find((prefix) => tag.name.startsWith(prefix))),
});

const { data: allProjects } = await useLazyFetch<
  Entry<TypeProjectSkeleton, "WITHOUT_UNRESOLVABLE_LINKS", "en-GB">[]
>("/api/projects");

const projectIds = computed(() =>
  [
    ...props.fields.filter?.filter((item): item is NonNullable<typeof item> => !!item).filter(isTypeProject).map((item) => item.sys.id) ?? [],
    ...selectedFilters[filterTitles.value.indexOf("Project")]?.map((filterName) => allProjects.value?.find((theme) => theme.fields.name === filterName)?.sys.id) ?? [],
  ],
);

const themeOrTopicIds = computed(() =>
  [
    ...props.fields.filter?.filter((item): item is NonNullable<typeof item> => !!item).filter((t) => isTypeTopic(t) || isTypeTheme(t)).map((item) => item.sys.id) ?? [],
    ...selectedFilters[filterTitles.value.indexOf("Theme")]?.map((filterName) => allThemes.value?.find((theme) => theme.fields.title === filterName)?.sys.id) ?? [],
  ],
);

// This is the main query for fetching content
const { data: filterArticles, status } = await useLazyFetch<
  EntryCollection<
    TypeArticleSkeleton | TypeReportSkeleton | TypeDownloadSkeleton,
    "WITHOUT_UNRESOLVABLE_LINKS",
    "en-GB"
  >
>(`/api/${{ Article: "articles", Report: "reports", Download: "downloads", Video: "downloads" }[props.fields.contentType]}`, {
  query: {
    articleTypeIds: computed(() =>
      props.fields.filter?.filter((item): item is NonNullable<typeof item> => !!item).filter(isTypeArticleType).map((item) => item.sys.id),
    ),
    authorIds: computed(() =>
      props.fields.filter?.filter((item): item is NonNullable<typeof item> => !!item).filter(isTypeStaff).map((item) => item.sys.id),
    ),
    projectIds,
    themeOrTopicIds,
    input,
    ...(props.fields.contentType === "Video" ? { contentTypes: ["Video"], tags: compiledTags } : {}),
    page: computed(() => Number(route.query[`page_${props.sys.id}`]) || 1),
    year: computed(() => selectedFilters[filterTitles.value.indexOf("Year")]?.join(",")),
    perPage: props.fields.setLimit ? 3 : perPage,
  },
  transform: useCircularProtect,
});

// Compute filtered articles based on selected filters
const articles = computed(() => {
  return props.fields.article?.length
    ? props.fields.article.filter(
      (article): article is NonNullable<typeof article> => !!article,
    )
    : filterArticles.value?.items;
});

// Compute pagination total based on whether articles are filtered or not
const paginationTotal = computed(() => {
  return props.fields.article
    ? props.fields.article.length
    : filterArticles.value?.total ?? 0;
});

// Watch for changes in search input and reset current page to 1
watch(input, () => (currentPage.value = 1));

// Watch for changes in current page and scroll into view
watch(currentPage, async (page) => {
  container.value?.$el.scrollIntoView({
    behavior: "smooth",
    block: "start",
    inline: "nearest",
  });

  // Update the URL so users can link to this page
  await navigateTo({ query: { [`page_${props.sys.id}`]: page }, hash: route.hash });
});

const filterPanelBlocks = computed(() => filterTitles.value.map((filterTitle) => {
  if (filterTitle === "Year" || filterTitle === "Video") {
    return allTags.value?.filter((tag) => tag.name.startsWith(filterTitle)).map((tag) => tag.name.replace(`${filterTitle}: `, "")).sort() ?? [];
  } else if (filterTitle === "Theme") {
    return allThemes.value?.map((theme) => theme.fields.title).filter((t): t is NonNullable<typeof t> => !!t).sort() ?? [];
  } else if (filterTitle === "Project") {
    return allProjects.value?.map((project) => project.fields.name).filter((t): t is NonNullable<typeof t> => !!t).sort() ?? [];
  } else {
    return [];
  }
}));

// Handle filter change event
const onFilterChange = (checked: boolean, id: string, blockIndex: number) => {
  if (!selectedFilters[blockIndex]) {
    selectedFilters[blockIndex] = [];
  }

  if (checked) {
    selectedFilters[blockIndex].push(id);
  } else {
    selectedFilters[blockIndex].splice(selectedFilters[blockIndex].indexOf(id), 1);
  }
};

const onClearClick = () => {
  Object.keys(selectedFilters).forEach((blockIndex) => {
    selectedFilters[parseInt(blockIndex)] = [];
  });
};
</script>

<template>
  <Container
    v-if="!fields.setLimit || articles?.length"
    ref="container"
    class="py-4 md:py-5"
  >
    <div class="flex flex-wrap gap-y-4">
      <div
        v-if="fields.displaySectionTitle"
        class="w-full"
      >
        <TextSectionTitle>{{ fields.title }}</TextSectionTitle>
      </div>
      <div
        v-if="!fields.article?.length && !fields.setLimit"
        class="mt-2 flex w-full flex-col justify-between md:mt-4 md:flex-row"
      >
        <div
          class="mb-3 flex h-5 w-full items-center rounded-sm border border-ui-grey2 md:mb-0 md:w-[70%] md:max-w-[700px]"
        >
          <NuxtIcon
            class="mx-2 size-4"
            name="search"
          />
          <input
            v-model="input"
            type="text"
            placeholder="Search for..."
            class="w-full border-none focus:outline-none"
          />
        </div>
        <UiFilterPanel
          :titles="filterTitles"
          :blocks="filterPanelBlocks"
          :as-radio="['Article', 'Report'].includes(props.fields.contentType) ? [0] : []"
          scroll-on-open
          @on-filter-change="onFilterChange"
          @on-clear-click="onClearClick"
        />
      </div>
      <div
        v-if="articles?.length"
        class="grid gap-4 lg:grid-cols-3"
        :class="{ 'opacity-10': status === 'pending' }"
      >
        <CardBlockArticleItem
          v-for="article in fields.setLimit ? articles?.slice(0, 3) : articles"
          :key="article.sys.id"
          :article="article"
        />
      </div>
      <div
        v-else-if="status !== 'pending'"
        class="flex w-full justify-center py-4"
      >
        <h3>0 results found</h3>
      </div>
      <div class="flex w-full justify-center">
        <NuxtLink
          v-if="fields.button"
          :to="fields.button?.url"
          :class="`btn btn-${fields.button?.type}`"
        >
          {{ fields.button?.title }}
        </NuxtLink>
      </div>
      <ClientOnly>
        <vue-awesome-paginate
          v-if="paginationTotal > 12 && !fields.setLimit"
          v-model="currentPage"
          :total-items="paginationTotal"
          :items-per-page="perPage"
          :max-pages-shown="4"
          class="pagination-container"
          type="link"
          :link-url="`?page_${sys.id}=[page]${route.hash}`"
        >
          <template #prev-button>
            prev
          </template>
          <template #next-button>
            next
          </template>
        </vue-awesome-paginate>
      </ClientOnly>
    </div>
  </Container>
</template>
